import * as React from 'react';

const WhyCTA = () => (
  <article className="w-full max-w-5xl pt-12 mx-auto my-auto">
    <p className="text-6xl font-semibold leading-none uppercase font-display text-brand-blue">
      <span>Frontline Removals </span>
      <br />
      <span className="text-brand-orange">privacy policy</span>
    </p>
  </article>
);

export default WhyCTA;
