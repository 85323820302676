import { Link } from 'gatsby';
import * as React from 'react';

const WhyBanner = () => (
  <section
    id="why-page-banner"
    className="flex items-center justify-center px-6 py-6 mt-12 text-center bg-brand-blue"
  >
    <h2 className="text-4xl font-semibold leading-tight uppercase font-display">
      <span className="text-white">We take the stress out of relocating </span>
      <Link
        to="https://www.frontlineremovals.com.au/contact/"
        className="inline-block px-2 leading-tight text-white rounded bg-brand-orange"
      >
        Move with us
      </Link>
    </h2>
  </section>
);

export default WhyBanner;
